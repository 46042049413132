import React from 'react';
import { useGetData } from '../../hooks/useGetData';
import DeprecatedDataService from '../../services/deprecated-data.service';
import RedocWrap from './redoc_wrap';

const UserApiSpecPage = (): React.ReactElement => {
  const [apiSpec, isLoading, failed] = useGetData(
    async () => await DeprecatedDataService.getUserApiSpec(),
  );

  if (isLoading) {
    return <div>Loading data...</div>;
  }

  if (failed !== '' || apiSpec === undefined) {
    return <div>Failed loading data: {failed}</div>;
  }
  return <RedocWrap spec={apiSpec} />;
};

export default UserApiSpecPage;
