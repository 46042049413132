import React from 'react';

const UserApiDocsPage = (): React.ReactElement => {
  const docUrl = window.location.origin + '/api/user/docs/';
  return (
    <div>
      <p>
        documentation is available at:
        <a href={docUrl}>{docUrl}</a>
      </p>
      <iframe
        style={{ height: 'calc(100vh - 110px)' }}
        title={'USER API documentation'}
        className="mh-100 w-100 shadow rounded"
        src={docUrl}
      />
    </div>
  );
};

export default UserApiDocsPage;
